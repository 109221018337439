import React, {useState} from 'react';
import {Popover, Button, Input} from 'antd';

const PopOverAttentionBtn = props => {
  const [popVisible, setPopVisible] = useState (false);
  const [remark, setRemark] = useState ('');

  const content = () => (
    <div>
      <Input onChange={e => setRemark (e.target.value)} value={remark} />
      <Button
        onClick={() => {
          props.SubmitRemarkWithRejection (props.type, props.id, remark);
          setPopVisible (false);

          setRemark ('');
        }}
        type="primary"
        style={{marginTop: 10}}
      >
        submit
      </Button>
      <Button
        onClick={() => {
          setPopVisible (false);

          setRemark ('');
        }}
        style={{marginTop: 10, marginLeft: 20}}
        danger
      >
        cancel
      </Button>
    </div>
  );

  return (
    <Popover
      placement="left"
      content={() => content ()}
      title={`PLEASE ADD A REMARK FOR TICKET ${props.type === 'approve' ? 'APPROVAL' : 'REJECTION'}`}
      trigger="click"
      visible={popVisible}
      onVisibleChange={() => setPopVisible (!popVisible)}
    >
      <Button
        onClick={() => setPopVisible (!popVisible)}
        style={{
          visibility: 'visible',
          paddingRight: props.type === 'approve' ? 22 : 22,
          paddingLeft: props.type === 'approve' ? 22 : 22,
          marginTop: 5,
          color: props.type !== 'approve' && '#FD8F46',
          background:props.type === 'approve' && '#38B6FF',
          border:props.type === 'approve' && '1px solid #38B6FF'
        }}
        type={props.type !== 'approve' ? 'link' : 'primary'}
        danger={props.type === 'approve' ? false : true}
        ghost={props.type !== 'approve' && true}
      >
        {props.type !== 'approve' ? <u>{props.title}</u> : props.title}
      </Button>
    </Popover>
  );
};

export default PopOverAttentionBtn;
